import React, { useState, useEffect } from "react";
import "../article-content/ArticleContent.scss";
import "./Research.scss";
import classNames from "classnames";
import { isMobile as detectIsMobile } from "react-device-detect";
// maybe use gatsby's markdown features instead? (would have to use MDX to redefine html tags: https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/)
import ReactMarkdown from "react-markdown";
import Img from "gatsby-image";
import { Section } from "react-scroll-section";

const Research = React.memo(({ locale, researchData }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(detectIsMobile);
    }, []);

    const shouldBeGrouped = (data) =>
        data ? data.Type === "Media" || data.Type === "Message" : false;
    let researchDataReorganized = [];
    for (let index = 0; index < researchData.length; index++) {
        let data = researchData[index].node.data;

        if (shouldBeGrouped(data)) {
            let groupSize = 0;
            let element = { node: { data: { Type: "Group", Content: [] } } };
            while (shouldBeGrouped(data)) {
                element.node.data.Content.push(data);
                index++;
                groupSize++;
                if (groupSize > 3) {
                    groupSize = 0;
                    researchDataReorganized.push(element);
                    element = {
                        node: { data: { Type: "Group", Content: [] } },
                    };
                }
                data = researchData[index]?.node.data;
            }
            if (element.node.data.Content.length > 0)
                researchDataReorganized.push(element);
        }
        if (!shouldBeGrouped(data) && data) {
            researchDataReorganized.push(researchData[index]);
        }
    }

    let sectionCounter = -1;

    const renderData = (data, key) => {
        if (data.Type === "Text" || data.Type === "Message") {
            return (
                <ReactMarkdown
                    className={classNames({
                        "research-message": data.Type === "Message",
                    })}
                    components={{
                        h1: ({ node, ...props }) => (
                            // eslint-disable-next-line
                            <h1 className="article_content__title" {...props} />
                        ),
                        a: ({ node, ...props }) => (
                            // eslint-disable-next-line
                            <a target="_blank" {...props} />
                        ),
                    }}
                    key={key}
                >
                    {data.Content}
                </ReactMarkdown>
            );
        }

        if (data.Type === "Quote") {
            return (
                <blockquote key={key}>
                    <ReactMarkdown>{data.Content}</ReactMarkdown>
                </blockquote>
            );
        }

        if (data.Type === "Section") {
            sectionCounter++;
            return (
                <Section id={`section-${sectionCounter}`} key={key}>
                    <ReactMarkdown key={key}>{data.Content}</ReactMarkdown>
                </Section>
            );
        }

        if (data.Type === "Animation") {
            return (
                <video
                    className={"animation"}
                    playsInline
                    autoPlay
                    loop
                    muted
                    key={key}
                >
                    <source
                        src={data.Attachments.localFiles[0].publicURL}
                        type="video/mp4"
                    />
                </video>
            );
        }

        if (data.Type === "Graph") {
            return (
                <figure className={"graph"} key={key}>
                    <Img
                        imgStyle={{
                            maxWidth: "calc(100vw - 5rem)",
                            objectFit: "contain",
                        }}
                        objectFit="contain"
                        fixed={
                            data?.Attachments?.localFiles[0].childImageSharp
                                .fixed
                        }
                    />
                    <figcaption>
                        <ReactMarkdown>{data.Content}</ReactMarkdown>
                    </figcaption>
                </figure>
            );
        }

        if (data.Type === "Full-width Media" || data.Type === "Media") {
            if (data.Attachments?.localFiles[0].extension !== "mp4") {
                if (!data.Attachments) return null;
                return (
                    <figure key={key}>
                        <Img
                            fluid={
                                data?.Attachments?.localFiles[0].childImageSharp
                                    .fluid
                            }
                        />
                        <figcaption>
                            <ReactMarkdown>{data.Content}</ReactMarkdown>
                        </figcaption>
                    </figure>
                );
            } else {
                return (
                    <figure>
                        <video
                            onClick={(e) => {
                                if (!isMobile) {
                                    e.target.play();
                                }
                            }}
                            onMouseLeave={(e) => {
                                if (!isMobile) {
                                    e.target.pause();
                                    e.target.currentTime = 0;
                                }
                            }}
                            onMouseEnter={(e) => {
                                if (!isMobile) {
                                    e.persist();
                                    e.target.title = "";
                                    e.target.play().catch((err) => {
                                        e.target.title =
                                            "Click the video to play it. Move the mouse outside the video to stop playback.";
                                    });
                                }
                            }}
                            controls={isMobile}
                            playsInline
                            preload={"metadata"}
                            key={key}
                        >
                            <source
                                src={
                                    data.Attachments.localFiles[0].publicURL +
                                    "#t=0.1"
                                }
                                type="video/mp4"
                            />
                        </video>
                        <figcaption>
                            <ReactMarkdown>{data.Content}</ReactMarkdown>
                        </figcaption>
                    </figure>
                );
            }
        }
    };

    return (
        <div className="article-template research">
            <div className={"articleContainer"}>
                <article>
                    <div className="top-content">
                        <div className="article_content">
                            <div className="article_content__col article_content__col--left">
                                {researchDataReorganized.map((e, i) => {
                                    const data = e.node.data;

                                    if (data.Type === "Group") {
                                        return (
                                            <div className={`group`} key={i}>
                                                {data.Content.map(
                                                    (groupElement, j) => {
                                                        return renderData(
                                                            groupElement,
                                                            `${i}-${j}`
                                                        );
                                                    }
                                                )}
                                            </div>
                                        );
                                    }

                                    return renderData(data, i);
                                })}
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    );
});

export default Research;
