import React, { memo, useState } from "react";
import { graphql } from "gatsby";
import { AnimatePresence } from "framer-motion";
import { navigate } from "gatsby";
import NavigationPillar from "components/navigation-pillar";
import SEO from "components/seo";
import Logo from "components/logo";
import "../layout/Layout.scss";
import BackgroundGradient from "components/background-gradient";
import CookieConsent from "../cookie-consent/CookieConsent";
import { motion } from "framer-motion";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import easings from "constants/easings";
import LanguageSelector from "components/language-selector";
import facebookShare from "images/shareImages/frontpage/share-facebook.jpg";
import FontPreload from "components/font-preload";
import Research from "../research/Research";
import ArticleSectionNavigation from "../article-section-navigation/ArticleSectionNavigation";
import { ScrollingProvider } from "react-scroll-section";

const ResearchPage = ({ children, data, location, pageContext, pageType }) => {
    const { locale } = pageContext;
    const {
        serviceMenuLinks,
        globalTexts,
        nativeLanguages,
        //TODO: check cookiePolicyLabel,
        frontpageData,
        researchData,
    } = data;

    const seo = {
        lang: locale,
        title: "What kids are saying through Earth Speakr",
        description:
            "The research collected below is primarily an act of listening, an attempt to understand the concerns and messages of voices of kids across the planet.",
        shareImageUrl: facebookShare,
    };

    const [transitionOut, setTransitionOut] = useState(false);
    const size = useWindowSize();
    const smallScreen = size.width < breakpoints.md;

    // LanguageSelector state
    const [isLanguageSelectorActive, setIsLanguageSelectorActive] =
        useState(false);

    const backgroundTransition = {
        initial: {
            opacity: 1,
            [size.width > breakpoints.md ? "x" : "y"]: "0%",
        },

        exit: {
            opacity: 1,
            [size.width > breakpoints.md ? "x" : "y"]:
                size.width > breakpoints.md
                    ? `${size.width - 300}px`
                    : `${size.height - 160}px`,
            transition: {
                delay: 0.2,
                type: "tween",
                ease: easings.heavy,
                duration: 0.6,
            },
        },
    };

    // all the slugs are the same (until we can get DatoCMS set up)
    const currentSlug = {
        edges: nativeLanguages.edges.map((i) => ({
            node: {
                locale: i.node.code,
                slug: "whatkidsaresaying",
            },
        })),
    };

    const sections = researchData.edges
        .filter((e) => e.node.data.Type === "Section")
        .map((e) => ({
            title: e.node.data.Content?.replace(/\n/g, "")
                .replace(/#/g, "")
                .replace(
                    "What kids are saying through Earth Speakr",
                    "What kids are saying"
                )
                .replace("Future discussion", "Future"),
        }));

    return (
        <>
            <SEO {...seo} />
            <FontPreload />

            <CookieConsent language={locale} />

            {children}

            <Logo
                locale={locale}
                className="article"
                logoLight={false}
                onClick={() => setTransitionOut(true)}
            />

            <BackgroundGradient
                className="article"
                transitionOut={transitionOut}
            ></BackgroundGradient>

            <motion.div
                variants={backgroundTransition}
                animate={transitionOut ? "exit" : "initial"}
                className="background-transition"
                onAnimationComplete={() => {
                    if (transitionOut) {
                        navigate(`/${locale}`);
                    }
                }}
            />

            <main
                className={`articlepage toolkits ${
                    transitionOut ? "articlepage--fadeOut" : ""
                }`}
            >
                <AnimatePresence exitBeforeEnter>
                    <ScrollingProvider>
                        <Research
                            locale={locale}
                            researchData={researchData.edges}
                        />
                        <ArticleSectionNavigation
                            language={locale}
                            items={sections}
                        />
                    </ScrollingProvider>
                </AnimatePresence>
                {!smallScreen && (
                    <LanguageSelector
                        nativeLanguages={nativeLanguages}
                        globalTexts={globalTexts}
                        isLanguageSelectorActive={isLanguageSelectorActive}
                        setIsLanguageSelectorActive={
                            setIsLanguageSelectorActive
                        }
                        currentSlug={currentSlug}
                        pageType={pageType}
                    />
                )}
            </main>

            <NavigationPillar
                text={frontpageData.aboutPageDescription}
                label={frontpageData.articlePageLink?.menuTitle}
                link={`/${locale}/${frontpageData.articlePageLink?.slug}`}
                takeActionLink={`/${locale}/${frontpageData.takeActionLink?.slug}`}
                takeActionLabel={frontpageData.takeActionLink?.menuTitle}
                logoText={frontpageData.logoText}
                locale={locale}
                items={data.articlePages.edges}
                serviceMenuLinks={serviceMenuLinks.edges}
                location={location}
                nativeLanguages={nativeLanguages}
                currentSlug={currentSlug}
                globalTexts={globalTexts}
                pageType={pageType}
                transitionOut={transitionOut}
                setTransitionOut={setTransitionOut}
            />
        </>
    );
};

export default memo(ResearchPage);

export const query = graphql`
    query ResearchPageData($locale: String!) {
        researchData: allAirtable(
            filter: { table: { eq: "research" } }
            sort: { fields: rowIndex, order: ASC }
        ) {
            edges {
                node {
                    data {
                        Type
                        Content
                        Attachments {
                            localFiles {
                                extension
                                publicURL
                                childImageSharp {
                                    fluid(maxWidth: 1280) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                    fixed(width: 1280) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        frontpageData: datoCmsFrontpage(locale: { eq: $locale }) {
            id: originalId
            aboutPageDescription
            appUrlAndroid
            appUrlIos
            appUrlLabel
            logoText
            seo {
                title
                description
                twitterCard
                image {
                    url
                }
            }
            takeActionLink {
                menuTitle
                slug
            }
            articlePageLink {
                menuTitle
                slug
            }
        }
        articlePages: allDatoCmsArticle(
            filter: { title: { ne: null }, locale: { eq: $locale } }
            sort: { fields: position }
        ) {
            edges {
                node {
                    slug
                    menuTitle
                    hideFromNavigation
                }
            }
        }
        serviceMenuLinks: allDatoCmsServiceMenu(
            filter: {
                locale: { eq: $locale }
                internalLink: { slug: { ne: null } }
            }
            sort: { fields: position }
        ) {
            edges {
                node {
                    menuTitle
                    locale
                    externalLink
                    linkType
                    internalLink {
                        slug
                    }
                }
            }
        }
        globalTexts: datoCmsGlobalText(locale: { eq: $locale }) {
            languageSelectorLabel
            mapMenuLabel
            streamingMenuLabel
            toolkitsMenuLabel
        }
        nativeLanguages: allDatoCmsLanguage(
            filter: { locale: { eq: $locale } }
        ) {
            edges {
                node {
                    id
                    code
                    nativeName
                }
            }
        }
    }
`;
